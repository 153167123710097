.CodeMirror {
  height: 90vh !important;
  font-size: 20px;
  width: 100vw;
}
/* .CodeEditor-IDE {
  width: 100px;
} */
@media (min-width: 550px) {
  .CodeMirror {
    width: 100%;
    height: 90vh;
  }
}
