.Homepage {
  background-color: black;
  width: 100vw;
  height: 100vh;
}
.Homepage div {
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Homepage img {
  width: 170px;
  margin-left: 5vw;
}
.Homepage div input {
  width: 60%;
  height: 50px;
  padding: 0;
  margin: 0;
  background-color: white;
  border: none;
  color: black;
  font-size: 20px;
  text-align: center;
  border-radius: 5px;
}
.Homepage div p {
  color: white;
}
.Homepage div input:active {
  border: 1px solid #3385d8;
}
.Homepage div button {
  width: 60%;
  height: 50px;
  margin-top: 10px;
  background-color: #263238;
  border: 1px solid #3385d8;
  border-radius: 5px;
  background-color: #3385d8;
  font-size: 20px;
  color: white;
}
.Homepage div button:hover {
  background-color: #e6b809;
  color: white;
}
@media (min-width: 600px) {
  .Homepage div input {
    width: 500px;
  }
  .Homepage div button {
    width: 500px;
  }
}
