.IdeDashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.IdeDashboard button {
  margin-right: 2px;
  width: 100px;
  font-size: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #3385d9;
  border: none;
  border-radius: 5px;
  color: white;
}
.IdeDashboard-main {
  width: 100%;
}
.IdeDashboard-header {
  background-color: #263238;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 66.67px
  /* padding-top: 20px; */
}
.header-content-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
}
/* .sharing-btn {
  justify-self: flex-end;
} */
.IdeDashboard-header img {
  width: 170px;
  margin-left: 10px;
}
.IdeDashboard-console {
  background-color: #dad6d6;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}
.IdeDashboard-console-heading {
  font-size: 20px;
  font-weight: bold;
  color: #7b7b7b;
  padding-left: 3vw;
}
.IdeDashboard-console-output {
  padding-left: 5vw;
  color: green;
  font-size: 20px;
}
.IdeDashboard-console-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.type-boolean {
  color: #b95131;
}
.type-number {
  color: #ff5370;
}
.type-array {
  color: purple;
}
.type-object {
  color: orange;
}
.error {
  color: rgb(226, 80, 80);
}
.IdeDashboard ul {
  display: none;
  list-style: none;
}
.IdeDashboard .checkbox {
  opacity: 0;
}
.IdeDashboard .checkbox:checked + ul {
  display: block;
}
@media (min-width: 550px) {
  .IdeDashboard {
    display: flex;
    flex-direction: row;
  }
  .IdeDashboard-header img {
    margin-left: 10px;
  }
  .IdeDashboard button {
    margin-right: 10px;
  }
  .IdeDashboard-console {
    width: 50%;
    height: 100vh;
  }
  .IdeDashboard-main {
    width: 50%;
  }
}


.Copied-text-span {
  opacity: 1;
  transition: 1s;
}